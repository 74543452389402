import {
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  Icon,
  Image,
  Skeleton,
  Button,
  Heading,
  Link,
  Tooltip,
  Divider,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/all";
import moment from "moment";

import { Wrapper } from "../../../components/Wrapper";
import PengetahuanDetailContext, {
  usePengetahuanDetailContext,
} from "../../../controller/pengetahuan/detail";


import PrettyRating from "pretty-rating-react";
import ModalReviewPengetahuan from "../../../components/Pengetahuan/ModalReview.";
import ReviewPengetahuan from "../../../components/Pengetahuan/Review";
import { parseISO, isAfter, addMinutes } from "date-fns";
import PengetahuanQuizContext from "../../../controller/pengetahuan/quiz";
import VideoPlayer from "../../../components/VideoPlayer";
import QuizPengetahuan from "../../../components/Pengetahuan/Quiz";
import AssesmentPengetahuan from "../../../components/Pengetahuan/Assesment";
import PengetahuanAssesmentContext from "../../../controller/pengetahuan/assesment";
import BoxPemateri from "../../../components/Pengetahuan/BoxPemateri";
import ModalConfirmationRegisterSpeaker from "../../../components/Pengetahuan/ModalConfirmationRegister";
import env from "@ludovicm67/react-dotenv";
import ModalConfirmationPengetahuan from "../../../components/Pengetahuan/ModalConfirmationPengetahuan";

/**
 * Component for displaying detailed knowledge content.
 * @returns {JSX.Element} The DetailPengetahuan component.
 * @author Bagas Prasetyadi
 */

const DetailPengetahuan = () => {
  const {
    fetchData,
    handleChangeStatusPengetahuan,
    setPengetahuanDetailState,
    postVideoCompleted,
  } = usePengetahuanDetailContext();
  const {
    pengetahuanItem,
    loadingData,
    loadingChangeStatusPengetahuan,
  } = usePengetahuanDetailContext().state;

  const playerRef = React.useRef(null);

  const pengetahuanId = window.location.pathname.split("/", 4)[3];

  const endDate =
    pengetahuanItem?.jadwal_selesai &&
    parseISO(pengetahuanItem?.jadwal_selesai.replace(" ", "T")); // Convert string to Date object

  const fiveMinutesAfterEnd = endDate && addMinutes(endDate, 5);
  const now = new Date();

  const isReadyToEnd = isAfter(now, fiveMinutesAfterEnd as Date);

  const isYoutubeStream = pengetahuanItem?.link_streaming?.includes("youtube");

  const isFinishedAssessment = pengetahuanItem?.has_assessment
    ? !!pengetahuanItem?.file_peserta
    : true;

  const noQuizAndAssesment =
    !pengetahuanItem?.has_assessment && !pengetahuanItem?.paket_soal_id;

  const showListPemateriSync =
    pengetahuanItem?.status_pengetahuan === "menunggu" &&
    pengetahuanItem?.jenis_pengetahuan === "synchronized";

  const showListPemateriAsync =
    pengetahuanItem?.status_pengetahuan === null &&
    pengetahuanItem?.jenis_pengetahuan === "asynchronized";


  const getYouTubeVideoId = (url) => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    return videoId;
  };


  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("ended", () => {
      postVideoCompleted(
        pengetahuanItem?.pengetahuan_materi_saya?.t_pengetahuan_id
      );
    });
  };

  const handleOpenModal = () => {
    setPengetahuanDetailState("isOpenModalConfirmationPengetahuan", true);
    if (
      pengetahuanItem?.jenis_pengetahuan === "synchronized" &&
      pengetahuanItem?.status_pengetahuan === null
    ) {
      setPengetahuanDetailState("statusPost", "menunggu");
    } else {
      setPengetahuanDetailState("statusPost", "mengikuti");
    }
  };

  const youtubeId =
    isYoutubeStream &&
    getYouTubeVideoId(pengetahuanItem?.pengetahuan_materi_saya?.link_streaming);

  useEffect(() => {
    fetchData(pengetahuanId);
  }, []);

  const colors = {
    star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
  };

  const isStaging = env.REACT_APP_ENVIRONMENT.toLowerCase() === "staging";

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      children: [
        "playToggle",
        "currentTimeDisplay",
        "durationDisplay",
        "volumePanel",
        "fullscreenToggle",
        "controlBar",
      ],
      skipButtons: {
        backward: 10,
        forward: isStaging && 30,
      },
    },
    userActions: {
      hotkeys: false,
      click: false,
    },
    techOrder: ["youtube"],
    sources: [
      {
        src: `https://www.youtube.com/watch?v=${pengetahuanItem?.pengetahuan_materi_saya?.path_file}`,
        type: "video/youtube",
      },
    ],
    youtube: {
      controls: 0,
      rel: 0,
      modestbranding: 1,
      disablekb: 1,
      fs: 0,
      enablejsapi: 1,
      hl: "",
    },
  };

  const shouldDisableFinishButton = () => {
    const isSynchronizedAndNotReady =
      pengetahuanItem?.jenis_pengetahuan === "synchronized" && !isReadyToEnd;

    const hasPendingQuiz =
      Boolean(
        pengetahuanItem?.pengetahuan_materi_saya?.t_pengetahuan_paket_soal_id
      ) &&
      pengetahuanItem?.pengetahuan_materi_saya?.t_pengetahuan_paket_soal
        ?.status_pengerjaan !== "selesai dikerjakan";

    const isAssessmentNotFinished = !isFinishedAssessment;

    const isVideoNotCompleted =
      !pengetahuanItem?.has_completed_video &&
      pengetahuanItem?.status_pengerjaan === null &&
      !noQuizAndAssesment &&
      pengetahuanItem?.pengetahuan_materi_saya?.category === "video";

    const isVideoWithNoQuizAndAssessment =
      !pengetahuanItem?.has_completed_video &&
      pengetahuanItem?.pengetahuan_materi_saya?.category === "video" &&
      noQuizAndAssesment;

    return (
      isSynchronizedAndNotReady ||
      hasPendingQuiz ||
      isAssessmentNotFinished ||
      isVideoNotCompleted ||
      isVideoWithNoQuizAndAssessment
    );
  };

  return (
    <>
      <Box m={{ base: 8, md: 12 }}>
        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
          {loadingData ? (
            <>
              <GridItem colSpan={{ base: 6, md: 2 }}>
                <Skeleton h="200px" w="full" mb={4} />
              </GridItem>
              <GridItem colSpan={{ base: 6, md: 4 }}>
                <Skeleton h="200px" w="full" mb={4} />
              </GridItem>
            </>
          ) : (
            <>
              {pengetahuanItem?.status_pengetahuan === "mengikuti" ||
              (pengetahuanItem?.status_pengetahuan === "selesai" &&
                pengetahuanItem?.jenis_pengetahuan === "asynchronized") ? (
                <>
                  <GridItem colSpan={{ base: 6, md: 4 }}>
                    <Box py={6} bgColor="white" rounded="xl">
                      <Box px={4}>
                        <Heading
                          as="h2"
                          fontSize="xl"
                          fontWeight="semibold"
                          mb={1}
                        >
                          {pengetahuanItem?.pengetahuan_materi_saya?.judul}
                        </Heading>

                        <Text
                          color="green"
                          fontWeight="semibold"
                          mb={2}
                          fontSize="md"
                        >
                          {
                            pengetahuanItem?.pengetahuan_materi_saya
                              ?.nama_pemateri
                          }
                        </Text>
                      </Box>

                      {pengetahuanItem?.jenis_pengetahuan ===
                      "asynchronized" ? (
                        <>
                          {pengetahuanItem?.pengetahuan_materi_saya
                            ?.category === "pdf" && (
                            <Grid
                              w="full"
                              templateColumns="repeat(3, 1fr)"
                              gap={4}
                              px={4}
                            >
                              <GridItem
                                colSpan={{ base: 3, md: 3 }}
                                height={{ base: "300px", md: "450px" }}
                              >
                                <iframe
                                  title={`${pengetahuanItem?.pengetahuan_materi_saya.judul}`}
                                  src={
                                    pengetahuanItem?.pengetahuan_materi_saya
                                      .path_file ?? ""
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                  }}
                                />
                              </GridItem>
                            </Grid>
                          )}

                          {pengetahuanItem?.pengetahuan_materi_saya
                            ?.category === "video" && (
                            <Grid
                              w="full"
                              templateColumns="repeat(3, 1fr)"
                              gap={4}
                              px={4}
                            >
                              <GridItem colSpan={{ base: 3, md: 3 }}>
                                <VideoPlayer
                                  options={videoJsOptions}
                                  onReady={handlePlayerReady}
                                  nonActiveProgressBar={!isStaging}
                                />
                              </GridItem>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          {isYoutubeStream ? (
                            <Grid
                              w="full"
                              templateColumns="repeat(3, 1fr)"
                              gap={4}
                              px={4}
                            >
                              <GridItem
                                colSpan={{ base: 3, md: 3 }}
                                h={{ base: "300px", md: "450px" }}
                              >
                                <iframe
                                  title="youtube video"
                                  src={`https://www.youtube.com/embed/${youtubeId}`}
                                  allowFullScreen
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </GridItem>
                            </Grid>
                          ) : (
                            <Grid
                              w="full"
                              templateColumns="repeat(3, 1fr)"
                              gap={4}
                              px={4}
                            >
                              <GridItem
                                colSpan={{ base: 3, md: 3 }}
                                h={"330px"}
                              >
                                <Box
                                  bg="#F6F5F8"
                                  h="full"
                                  rounded="md"
                                  py={4}
                                  display="flex"
                                  flexDir="column"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Text color="gray.900" fontSize="lg">
                                    Pembelajaran online Anda telah dimulai.
                                  </Text>
                                  <Text color="gray.500" fontSize="md" mt={3}>
                                    Segera masuk ke sesi anda
                                  </Text>
                                  <Link
                                    href={
                                      pengetahuanItem?.pengetahuan_materi_saya
                                        ?.link_streaming ?? "#"
                                    }
                                    isExternal
                                    bg="blue.500"
                                    color="white"
                                    px={4}
                                    py={2}
                                    rounded="md"
                                    mt={2}
                                    _hover={{ bg: "blue.600" }}
                                    textAlign="center"
                                    display="inline-block"
                                  >
                                    Ikuti Sesi
                                  </Link>
                                </Box>
                              </GridItem>
                            </Grid>
                          )}
                        </>
                      )}

                      {pengetahuanItem?.status_pengetahuan === "mengikuti" && (
                        <>
                          <Divider mt={4} />

                          <Flex justifyContent="start" pl={4}>
                            <Tooltip
                              label='Anda dapat mengklik tombol "Selesai Pengetahuan" lima menit setelah Video Live/Zoom berakhir'
                              placement="top"
                              gutter={2}
                              maxW="none"
                              whiteSpace="nowrap"
                              hasArrow
                              isDisabled={
                                pengetahuanItem.jenis_pengetahuan ===
                                  "asynchronized" || isReadyToEnd
                              }
                            >
                              <Box>
                                <Button
                                  colorScheme="orange"
                                  mt={4}
                                  onClick={() =>
                                    handleChangeStatusPengetahuan(pengetahuanId, "selesai")
                                  }
                                  isLoading={loadingChangeStatusPengetahuan}
                                  isDisabled={shouldDisableFinishButton()}
                                >
                                  Selesai Pengetahuan
                                </Button>
                              </Box>
                            </Tooltip>
                          </Flex>
                        </>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 6, md: 2 }}>
                    <Box py={6} bgColor="white" rounded="xl">
                      <QuizPengetahuan />
                    </Box>
                    <Box py={6} bgColor="white" rounded="xl" mt={4}>
                      <AssesmentPengetahuan />
                    </Box>
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem colSpan={{ base: 6, md: 2 }}>
                    <Image
                      src={pengetahuanItem?.thumbnail}
                      w="full"
                      h="250px"
                      rounded="xl"
                      objectFit="cover"
                      alt={`image-${pengetahuanItem?.title}`}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 6, md: 4 }}>
                    <Box rounded="xl" w="full" h="full" p={4} bg="green.700">
                      <Text
                        fontSize="2xl"
                        fontWeight="semibold"
                        color="white"
                        mb={3}
                      >
                        {pengetahuanItem?.title}
                      </Text>
                      <Text color="white" fontSize="sm" mb={3}>
                        {pengetahuanItem?.nama_penyelenggara ?? ""}
                      </Text>
                      <Flex flexDirection={{ base: "column", md: "row" }}>
                        <Flex mr={4}>
                          <PrettyRating
                            value={pengetahuanItem?.rata_rata_rating ?? 0}
                            colors={colors.star}
                          />
                          <Text color="white" fontSize="sm" mx={2}>
                            {pengetahuanItem?.rata_rata_rating}
                          </Text>
                          <Text color="white" fontSize="sm">
                            ({pengetahuanItem?.review_count}{" "}
                            <Text color="gray.200" as="span" fontSize="sm">
                              reviews
                            </Text>
                            )
                          </Text>
                        </Flex>
                        <Flex mt={{ base: 2, md: 0 }}>
                          {pengetahuanItem?.jenis_pengetahuan ===
                          "asynchronized" ? (
                            <>
                              <Icon
                                as={AiOutlineCalendar}
                                color="#FFDA6A"
                                boxSize={5}
                                mr={2}
                              />
                              <Text fontSize="sm" color="white">
                                {pengetahuanItem?.created_at}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Icon
                                as={AiOutlineCalendar}
                                color="#FFDA6A"
                                boxSize={5}
                                mr={2}
                              />
                              <Text fontSize="sm" color="white">
                                {moment(pengetahuanItem?.jadwal_mulai).format(
                                  "DD MMMM YYYY HH:mm"
                                )}{" "}
                                -{" "}
                                {moment(pengetahuanItem?.jadwal_selesai).format(
                                  "DD MMMM YYYY HH:mm"
                                )}
                              </Text>
                            </>
                          )}
                        </Flex>
                      </Flex>
                      {pengetahuanItem?.status_pengetahuan === null && (
                        <Button
                          bg="orange.300"
                          color="white"
                          _hover={{ bg: "orange.400" }}
                          _active={{ bg: "orange.400" }}
                          mt={4}
                          onClick={() => {
                            handleOpenModal();
                          }}
                        >
                          {pengetahuanItem.jenis_pengetahuan ===
                            "synchronized" &&
                          pengetahuanItem?.status_pengetahuan === null
                            ? "Daftar"
                            : "Ikuti"}
                        </Button>
                      )}
                    </Box>
                  </GridItem>
                </>
              )}
            </>
          )}
        </Grid>
        <Grid templateColumns="repeat(7, 1fr)" gap={5}>
          {loadingData ? (
            <GridItem colSpan={{ base: 7, md: 4 }}>
              <Skeleton h="400px" w="full" mb={4} />
            </GridItem>
          ) : (
            <GridItem colSpan={{ base: 7, md: 4 }}>
              {pengetahuanItem?.status_pengetahuan === "selesai" && (
                <>
                  <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                      Selamat Kamu Telah Selesai Mengikuti Pengetahuan Ini!
                    </Text>
                    <Text fontSize="sm" color="gray.400" mb={3}>
                      Terus Belajar dan praktikan ilmu yang telah anda pelajari
                      di pengetahuan ini agar lebih bermanfaat{" "}
                    </Text>
                    {!pengetahuanItem?.has_reviewed && (
                      <ModalReviewPengetahuan />
                    )}
                  </Box>
                  {pengetahuanItem?.jenis_pengetahuan === "synchronized" && (
                    <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
                      <Text fontSize="lg" fontWeight="semibold" mr={2} mb={2}>
                        Materi yang telah kamu pelajari
                      </Text>
                      <Box>
                        <Stack direction="row" spacing={1}>
                          <Text fontWeight="semibold" fontSize="md">
                            {
                              pengetahuanItem?.pengetahuan_materi_saya
                                ?.nama_pemateri
                            }{" "}
                            |
                          </Text>
                          <Text fontSize="md">Pemateri</Text>
                        </Stack>
                        <Text fontSize="sm" mt={2}>
                          {pengetahuanItem?.pengetahuan_materi_saya?.judul}
                        </Text>
                        <Stack direction="row" mt={2} spacing={2}>
                          {pengetahuanItem?.pengetahuan_materi_saya
                            ?.has_quiz && (
                            <Flex alignItems="center">
                              <Text fontSize="sm" color="gray.500" mr={2}>
                                Nilai Quiz:
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color="green.600"
                              >
                                {pengetahuanItem.nilai_quiz}
                              </Text>
                            </Flex>
                          )}
                        </Stack>
                        <Stack direction="row" mt={2} spacing={2}>
                          {pengetahuanItem?.pengetahuan_materi_saya
                            ?.t_pengetahuan_asesmen?.selesai_pada && (
                            <Flex alignItems="center">
                              <Text fontSize="sm" color="gray.500" mr={2}>
                                Status Asesmen:
                              </Text>
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                color="green.600"
                              >
                                Selesai Dikerjakan
                              </Text>
                            </Flex>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  )}
                </>
              )}
              {(showListPemateriAsync || showListPemateriSync) &&
                pengetahuanItem?.pengetahuan_materi && (
                  <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
                    <Text fontSize="lg" fontWeight="semibold">
                      Ikuti Pemateri
                    </Text>
                    {pengetahuanItem?.pengetahuan_materi.map((item, i) => (
                      <BoxPemateri key={i} item={item} />
                    ))}
                  </Box>
                )}
              <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
                <Flex alignItems="center">
                  <Text fontSize="lg" fontWeight="semibold" mr={2}>
                    Informasi Umum
                  </Text>
                </Flex>
                <Flex alignItems="center" mt={2}>
                  <Icon as={AiOutlineClockCircle} boxSize={4} mr={2} />
                  <Text fontSize="sm">
                    {moment(
                      pengetahuanItem?.created_at,
                      "YYYY-MM-DD HH:mm:ss"
                    ).fromNow()}
                  </Text>
                </Flex>
                <Box
                  mt={4}
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: pengetahuanItem?.description || "",
                  }}
                ></Box>
              </Box>
            </GridItem>
          )}
          <GridItem colSpan={{ base: 7, md: 3 }}>
            <ReviewPengetahuan />
          </GridItem>
        </Grid>
        <ModalConfirmationRegisterSpeaker />
        <ModalConfirmationPengetahuan pengetahuanId={pengetahuanId}/>
      </Box>
    </>
  );
};

const WrapperMain = () => {
  return (
    <Wrapper>
      <DetailPengetahuan />
    </Wrapper>
  );
};

const DetailPengetahuanMain = () => {
  return (
    <PengetahuanDetailContext.Provider>
      <PengetahuanQuizContext.Provider>
        <PengetahuanAssesmentContext.Provider>
          <WrapperMain />
        </PengetahuanAssesmentContext.Provider>
      </PengetahuanQuizContext.Provider>
    </PengetahuanDetailContext.Provider>
  );
};

export default DetailPengetahuanMain;
