import { useToast } from "@chakra-ui/react";
import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../../../api/axiosInstance";
import { PengetahuanItem } from "../../../../types/pengetahuan";

interface Peserta {
  id: number;
  name: string;
  email: string;
  nik: string;
  nip: string | null;
  phone_number: string;
  jenis_user: number;
  level: string;
  email_verified_at: string | null;
  path_foto: string;
  created_at: string;
  updated_at: string;
  last_seen: string;
  qr_image_alumni: string | null;
  status_qr_alumni: string;
  is_user_sso: boolean | null;
  is_guru_madrasah: number;
}

export interface Review {
  id: number;
  pengetahuan_id: number;
  user_id: number;
  rating: number;
  komentar: string;
  created_at: string;
  updated_at: string;
  peserta: Peserta;
}

interface IState {
  state: {
    url: string;
    urlRegisterPengetahuan: string;
    urlPostVideoCompleted: string;
    pengetahuanItem: PengetahuanItem | null;
    loadingData: boolean;
    loadingChangeStatusPengetahuan: boolean;
    isVideoEnded: boolean;
    statusPost: string;
    review: {
      url: string;
      isLoadingPost: boolean;
      rating: number;
      comment: string;
      isLoadingData: boolean;
      listData: Review[];
      sortByRating: string;
      page: number;
      perPage: number;
      currentPage: number;
      totalRow: number;
    };
    urlRegisterSpeaker: string;
    isLoadingAction: boolean;
    isOpenModalConfirmation: boolean;
    isOpenModalConfirmationPengetahuan: boolean;
    speakerId: number;
  };
  fetchData: Function;
  handlePostReview: Function;
  setPengetahuanDetailState: Function;
  setReviewState: Function;
  getReviewData: Function;
  handleChangeStatusPengetahuan: Function;
  registerSpeaker: Function;
  postVideoCompleted: Function;
}

const initialState: IState = {
  state: {
    url: "public/pengetahuan/",
    urlRegisterPengetahuan: "management_content/pengetahuan_saya",
    urlPostVideoCompleted: "history_video/completed",
    pengetahuanItem: null,
    loadingData: false,
    loadingChangeStatusPengetahuan: false,
    isVideoEnded: false,
    statusPost: "",
    review: {
      url: "management_content/pengetahuan/",
      isLoadingPost: false,
      rating: 0,
      comment: "",
      isLoadingData: false,
      listData: [],
      sortByRating: "",
      page: 1,
      perPage: 3,
      currentPage: 0,
      totalRow: 0,
    },
    urlRegisterSpeaker: "management_content/pengetahuan_saya/materi",
    isLoadingAction: false,
    isOpenModalConfirmation: false,
    isOpenModalConfirmationPengetahuan: false,
    speakerId: 0,
  },
  fetchData: () => {},
  handlePostReview: () => {},
  setPengetahuanDetailState: () => {},
  setReviewState: () => {},
  getReviewData: () => {},
  handleChangeStatusPengetahuan: () => {},
  registerSpeaker: () => {},
  postVideoCompleted: () => {},
};

const Context = createContext<IState>(initialState);
const { Provider: PengetahuanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const { url, review, urlRegisterPengetahuan, urlPostVideoCompleted, pengetahuanItem, speakerId } =
    state.state;

  const registerSpeaker = async (status: string) => {
    setPengetahuanDetailState("isLoadingAction", true);
    try {
      const response = await axiosInstance.post(
        `${urlRegisterPengetahuan}/${pengetahuanItem?.id} `,
        {
          pengetahuan_materi_id: speakerId,
          status_pengetahuan: status,
        }
      );
      toast({
        title: response?.data?.message ?? "Berhasil megikuti pengetahuan",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setPengetahuanDetailState("isOpenModalConfirmation", false);
      if (pengetahuanItem) {
        fetchData(pengetahuanItem?.id.toString());
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.message ?? "Sedang ada kesalahan pada sistem",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      throw error;
    } finally {
      setPengetahuanDetailState("isLoadingAction", false);
    }
  };

  const handleChangeStatusPengetahuan = async (
    pengetahuanId: string,
    status: string
  ) => {
    setPengetahuanDetailState("loadingChangeStatusPengetahuan", true);

    const urlPost = `${urlRegisterPengetahuan}?pengetahuan_id=${pengetahuanId}&status_pengetahuan=${status}`;
    const urlUpdate = `${urlRegisterPengetahuan}/${pengetahuanId}?status_pengetahuan=${status}`;

    const isAsynchronizePost =
      pengetahuanItem?.jenis_pengetahuan === "asynchronized" &&
      status === "mengikuti";

    const urlChangeStatus =
      status === null || status === "menunggu" || isAsynchronizePost
        ? urlPost
        : urlUpdate;

    try {
      const response = await axiosInstance.post(urlChangeStatus);

      toast({
        title: response?.data?.message ?? "Berhasil megikuti pengetahuan",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      fetchData(pengetahuanId);

      return response;
    } catch (error) {
      toast({
        title:
          error?.response?.data?.message ?? "Sedang ada kesalahan pada sistem",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      throw error;
    } finally {
      setPengetahuanDetailState("isOpenModalConfirmationPengetahuan", false);
      setPengetahuanDetailState("loadingChangeStatusPengetahuan", false);
    }
  };

  const getReviewData = async (id: string) => {
    setReviewState("isLoadingData", true);
    try {
      const response = await axiosInstance.get(review.url + id + "/review", {
        params: {
          rating: review.sortByRating,
          page: review.page,
          per_page: review.perPage,
        },
      });
      setReviewState("listData", response.data?.data?.data);
      setReviewState("currentPage", response.data?.data?.current_page);
      setReviewState("totalRow", response.data?.data?.total);
    } catch (error) {
      throw error;
    } finally {
      setReviewState("isLoadingData", false);
    }
  };

  const handlePostReview = async (idPengetahuan: string) => {
    const response = await axiosInstance.post(
      review.url + idPengetahuan + "/review",
      {
        rating: review.rating,
        komentar: review.comment,
      }
    );

    return response;
  };
  const setPengetahuanDetailState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const setReviewState = (key: keyof IState["state"]["review"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        review: {
          ...prevState.state.review,
          [key]: value,
        },
      },
    }));
  };

  const postVideoCompleted = async (id: string) => {
    try {
      const response = await axiosInstance.post(urlPostVideoCompleted, {
        type: 'pengetahuan',
        video_id: id,
      });

      if (pengetahuanItem) {
        fetchData(pengetahuanItem?.id.toString());
      }

      return response
    } catch (error) {
      toast({
        title:"Sedang ada kesalahan pada sistem",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      throw error;
    }
  };

  const fetchData = async (id: string) => {
    setPengetahuanDetailState("loadingData", true);
    try {
      const response = await axiosInstance.get(url + id);

      setPengetahuanDetailState("pengetahuanItem", response.data.data);
      setPengetahuanDetailState("loadingData", false);
    } catch (error) {
      setPengetahuanDetailState("loadingData", false);
      throw error;
    }
  };

  return (
    <PengetahuanDetailProvider
      value={{
        state: state.state,
        fetchData,
        handlePostReview,
        setPengetahuanDetailState,
        setReviewState,
        getReviewData,
        handleChangeStatusPengetahuan,
        registerSpeaker,
        postVideoCompleted,
      }}
    >
      {children}
    </PengetahuanDetailProvider>
  );
};

export const usePengetahuanDetailContext = () => useContext(Context);

const PengetahuanDetailContext = {
  usePengetahuanDetailContext,
  Provider,
};

export default PengetahuanDetailContext;
