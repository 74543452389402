import env from "@ludovicm67/react-dotenv";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import history from "../../../../history";
import Loader from "../../components/Loader";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Box,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { SetLocalStorage } from "../../../misc/UseLocalStorage";

/**
 * The RequireAccess component is used to authenticate users
 * and grant access to related pages based on query parameters.
 * @author Bagas Prasetyadi
 */

const RequireAccess = () => {
  const signIn = useSignIn();
  // Mendapatkan query parameters dari URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);

  // Mendapatkan nilai dari query parameter dengan nama tertentu
  const token = urlSearchParams.get("token");
  const eventId = urlSearchParams.get("event_id");
  const userNik = urlSearchParams.get("user_nik") ?? "";
  const eventName = urlSearchParams.get("event_name") ?? "";
  const action = urlSearchParams.get("action") ?? "";
  const actionDetail = urlSearchParams.get("action_detail") ?? "";
  const sourcePelatihan = urlSearchParams.get("source");
  const startDate = urlSearchParams.get("start_date");
  const endDate = urlSearchParams.get("end_date");
  const isPretest = urlSearchParams.get("is_pretest");
  const isPretestParam = isPretest === "true" ? JSON.parse(isPretest) : false;

  const baseUrl = env.REACT_APP_BE_PROD_BASE_URL;

  //mendapatkan data pelatihan berdasarkan event id
  const getPelatihanByEventId = async (pintarToken: string) => {
    const testType = isPretestParam ? "pretest" : "tes-akhir";
    try {
      const res = await axios.get(baseUrl + "pelatihan/" + testType, {
        params: {
          event_id: eventId,
        },
        headers: {
          Authorization: `Bearer ${pintarToken}`,
        },
      });

      return res.data?.data;
    } catch (error) {
      toast({
        title: error.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  //handle redirect url
  const handleRedirect = (redirectPath) => {
    history.replace(redirectPath);
    window.location.reload();
  };

  //data pelatihan
  const handleRegisterPelatihan = async (pelatihanId, accessToken) => {
    try {
      const registerPelatihan = await axios.post(
        baseUrl + `pelatihan_saya?pelatihan_id=${pelatihanId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return registerPelatihan;
    } catch (error) {
      setIsError(true);
      setIsLoading(false);

      toast({
        title: error.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });

      return error.response;
    }
  };

  /**
   * Mengelola akses ke pelatihan berdasarkan token akses yang diberikan.
   * Jika tindakan adalah 'access-pelatihan' dan detail tindakan adalah 'get-pretest',
   * maka akan mengarahkan pengguna ke halaman daftar peserta pada detail pelatihan.
   * Jika tidak, maka akan mengarahkan pengguna berdasarkan id pelatihan.
   * @param {string} accessToken - Token akses untuk pengguna.
   */
  const handleAccessPelatihan = async (accessToken) => {
    try {
      const dataPelatihan = await getPelatihanByEventId(accessToken);

      if (dataPelatihan?.length) {
        if (
          dataPelatihan[0]?.status_pelatihan?.toLowerCase() ===
            "belum mengikuti" ||
          dataPelatihan[0]?.status_pelatihan === null
        ) {
          if (actionDetail === "") {
            const resRegisterPelatihan = await handleRegisterPelatihan(
              dataPelatihan[0]?.id,
              accessToken
            );
            if (resRegisterPelatihan?.status !== 200) {
              return;
            }
          }
        }

        const redirectPath =
          actionDetail === "get-pretest"
            ? `/admin/detail/manajemen_pelatihan/data_pelatihan/${dataPelatihan[0]?.uuid}?action=${actionDetail}&userNik=${userNik}`
            : `/detail/pelatihan/${dataPelatihan[0]?.uuid}`;

        handleRedirect(redirectPath);
      } else {
        setIsError(true);
        setIsLoading(false);
        toast({
          title: "Mohon Maaf, sedang ada kesalahan pada sistem",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      toast({
        title: "Mohon Maaf, sedang ada kesalahan pada sistem",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  };

  /**
   * Mengakses Pelatihan Pintar berdasarkan respons yang diberikan.
   * Jika tindakan adalah 'create-pelatihan', maka akan mengarahkan ke halaman pembuatan pelatihan.
   * Jika tindakan adalah 'access-pelatihan', maka akan mengelola akses ke pelatihan berdasarkan respons.
   * @param {any} response - Objek respons.
   */
  const accessPelatihanPintar = async (response) => {
    try {
      let redirectPath = "/";

      switch (action) {
        case "create-pelatihan":
          redirectPath = `/admin/tambah/manajemen_pelatihan?name=${eventName}&source=${sourcePelatihan}&action=${action}&eventId=${eventId}&startDate=${startDate}&endDate=${endDate}&isPretest=${isPretestParam}`;
          handleRedirect(redirectPath);
          break;
        case "access-pelatihan":
          await handleAccessPelatihan(response?.data?.data?.access_token);
          break;
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  };

  const accessCertificatePintar = async ({
    action,
    accessToken,
  }: {
    action: string;
    accessToken: string;
  }) => {
    switch (action) {
      case "generate-certificate":
        window.location.href = `/admin/manajemen_sertifikat?eventId=${eventId}`;
        break;
      case "download-certificate":
        const dataPelatihan = await getPelatihanByEventId(accessToken);
        window.location.href = `/detail/pelatihan/${dataPelatihan[0]?.uuid}?action=${action}`;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(baseUrl + "public/redirect", {
          params: {
            token,
          },
        });

        if (response?.status === 200) {
          const { data } = response?.data;

          const user = {
            id: data.id,
            username: data.name,
            nip: data?.nip,
            nik: data?.nik,
            email: data?.email,
            level: data?.level,
            jenis_user: data?.jenis_user,
          };

          //login pintar dengan token dari respon api redirect
          signIn({
            token: data?.access_token,
            expiresIn: data?.expires_in,
            tokenType: "Bearer",
            authState: user,
          });

          // set image foto
          SetLocalStorage({ name: "path_photo", value: data?.path_foto });

          // set level
          SetLocalStorage({ name: "level", value: data?.level });

          // set status pengisian
          SetLocalStorage({
            name: "status_pengisian",
            value: data?.m_peserta?.status_pengisian,
          });

          if (action.includes("certificate")) {
            accessCertificatePintar({
              action,
              accessToken: data?.access_token,
            });
          } else {
            accessPelatihanPintar(response);
          }
        }
      } catch (error) {
        setIsLoading(false);
        const { data } = error.response.data;
        const { nama, nip, nik, email, path_foto, jenis_user } = data;

        setIsError(true);

        toast({
          title: error.response.data.message.error,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });

        // jika user tidak ditemukan maka redirect ke halaman register
        setTimeout(() => {
          if (error.response.status === 400) {
            window.location.href = `/register?name=${nama}&nip=${nip}&nik=${nik}&email=${email}&pathFoto=${path_foto}&jenisUser=${jenis_user}&autoFill=${true}`;
          }
        }, 2000);
      }
    };

    fetchData();
  }, []);

  return (
    <Box as="div" maxW="7xl" mx="auto" px={{ base: "4", lg: "8" }} my={10}>
      {isLoading && (
        <Loader loadingText="Sedang dalam proses autentikasi. Mohon tunggu sebentar..." />
      )}
      {isError && (
        <Box w="full">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>
              Mohon Maaf, sedang ada kesalahan pada sistem
            </AlertTitle>
          </Alert>
          <Flex alignItems="center">
            <Button mt={3} mr={3} onClick={() => window.location.reload()}>
              Coba Lagi
            </Button>
            <Button mt={3} colorScheme="red" onClick={() => window.close()}>
              Tutup Aplikasi
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default RequireAccess;
