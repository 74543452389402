/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../data/presenters/MateriPresenter";
import { Materi } from "../../../../../entities/Materi";
import { JenisPelatihan } from "../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../data/presenters/JenisPelatihanPresenter";
import axiosInstance from "../../../../api/axiosInstance";
import { PengetahuanItem } from "../../../../types/pengetahuan";
import { useToast } from "@chakra-ui/react";
import { isAfter, isBefore, parse } from "date-fns";

interface Option {
  label: string;
  value: string;
  icon: string;
  total: number;
  totalAsync: number;
  totalSync: number;
}

interface FetchDataParams {
  status?: string;
  jenis_pengetahuan?: string;
}

interface Pagination {
  current_page: number;
  total_rows: number;
}

interface DataJP {
  jp: number;
  point: number;
}

interface IState {
  isLoading: boolean;
  isLoadingSync: boolean;
  jumlahPage: number;
  page: number;
  perPage: number;
  pageSync: number;
  perPageSync: number;
  keyword: string;
  tahun: string;
  kategori_soal: string;
  jenis_soal: string;
  totalMateri: number;
  materi: Materi[];
  jenis_materi_id: string;
  jenis_materi: string[];
  jenisPelatihan: JenisPelatihan[];
  checkVideo: boolean;
  checkPDF: boolean;
  checkPPT: boolean;
  checkAudio: boolean;
  checkAll: boolean;
  url: string;
  urlKategoriPengetahuan: string;
  urlPengetahuanSaya: string;
  listData: PengetahuanItem[];
  listDataSync: PengetahuanItem[];
  listKategoriPengetahuan: Option[];
  category: string;
  pagination: Pagination | null;
  paginationSync: Pagination | null;
  kategori_pengetahuan_id: string;
  sort_by: string;
  listPengetahuanSaya: PengetahuanItem[];
  totalPengetahuanSaya: number;
  isLoadingPengetahuanSaya: boolean;
  pagePengetahuanSaya: number;
  perPagePengetahuanSaya: number;
  jumlahBintang: number;
  isLoadingDownloadRaport: boolean;
  dataJP: DataJP | null;
}

interface InitialState {
  state: IState;
  getMateri: Function;
  setPage: Function;
  setKeyword: Function;
  searchMateri: Function;
  getJenisPelatihan: Function;
  filterJenisPelatihan: Function;
  filterJenisMateri: Function;
  filterSemuaJenisMateri: Function;
  fetchData: Function;
  setState: Function;
  getListKategoriPengetahuan: Function;
  getPengetahuanSaya: Function;
  getJumlahBintang: Function;
  downloadRaport: Function;
  getDetailJP: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingSync: false,
    jumlahPage: 0,
    page: 1,
    perPage: 6,
    pageSync: 1,
    perPageSync: 6,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 1,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
    url: "",
    urlKategoriPengetahuan: "kategori_pengetahuan",
    urlPengetahuanSaya: "management_content/pengetahuan_saya",
    listData: [],
    listDataSync: [],
    listKategoriPengetahuan: [],
    category: "",
    pagination: null,
    paginationSync: null,
    kategori_pengetahuan_id: "",
    sort_by: "",
    listPengetahuanSaya: [],
    totalPengetahuanSaya: 0,
    isLoadingPengetahuanSaya: false,
    pagePengetahuanSaya: 1,
    perPagePengetahuanSaya: 5,
    jumlahBintang: 0,
    isLoadingDownloadRaport: false,
    dataJP: null,
  },
  getMateri: () => {},
  searchMateri: () => {},
  setPage: () => {},
  setKeyword: () => {},
  getJenisPelatihan: () => {},
  filterJenisPelatihan: () => {},
  filterJenisMateri: () => {},
  filterSemuaJenisMateri: () => {},
  fetchData: () => {},
  setState: () => {},
  getListKategoriPengetahuan: () => {},
  getPengetahuanSaya: () => {},
  getJumlahBintang: () => {},
  downloadRaport: () => {},
  getDetailJP: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PengetahuanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingSync: false,
    jumlahPage: 0,
    page: 1,
    perPage: 6,
    pageSync: 1,
    perPageSync: 6,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 0,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
    url: "public/pengetahuan",
    urlPengetahuanSaya: "management_content/pengetahuan_saya",
    listData: [],
    listDataSync: [],
    category: "",
    pagination: null,
    paginationSync: null,
    urlKategoriPengetahuan: "kategori_pengetahuan",
    listKategoriPengetahuan: [],
    kategori_pengetahuan_id: "",
    sort_by: "",
    listPengetahuanSaya: [],
    totalPengetahuanSaya: 0,
    isLoadingPengetahuanSaya: false,
    pagePengetahuanSaya: 1,
    perPagePengetahuanSaya: 5,
    jumlahBintang: 0,
    isLoadingDownloadRaport: false,
    dataJP: null,
  });
  const toast = useToast();

  const downloadRaport = async () => {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0 = Januari, 6 = Juli

    // Membuat objek tanggal untuk validasi
    const januaryFirst = parse("01-01", "dd-MM", new Date());
    const januaryFourteenth = parse("14-01", "dd-MM", new Date());
    const julyFirst = parse("01-07", "dd-MM", new Date());
    const julyFourteenth = parse("14-07", "dd-MM", new Date());

    // Validasi apakah hari ini ada di rentang 1-14 Januari atau 1-14 Juli
    const isDownloadWindow =
      (currentMonth === 0 &&
        isAfter(today, januaryFirst) &&
        isBefore(today, januaryFourteenth)) ||
      (currentMonth === 6 &&
        isAfter(today, julyFirst) &&
        isBefore(today, julyFourteenth));

    if (!isDownloadWindow) {
      toast({
        title: "Download tidak diizinkan.",
        description:
          "Download rapot hanya bisa dilakukan pada tanggal 1-14 Januari dan 1-14 Juli.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setState((prevstate) => ({
      ...prevstate,
      isLoadingDownloadRaport: true,
    }));
    try {
      const response = await axiosInstance.get(
        state.urlPengetahuanSaya + "/raport/download",
        {
          responseType: "blob",
        }
      );

      // Construct the filename from the content-disposition header
      const filename = response.headers["content-disposition"];

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", filename);

      document.body.appendChild(link);

      link.click();

      link.parentNode?.removeChild(link);

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast({
        title: "Gagal mengunduh rapot.",
        description: error?.message ?? "",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      throw error;
    } finally {
      setState((prevstate) => ({
        ...prevstate,
        isLoadingDownloadRaport: false,
      }));
    }
  };

  const getJumlahBintang = async () => {
    try {
      const response = await axiosInstance.get("user/get-jumlah-bintang");

      setState((prevstate) => ({
        ...prevstate,
        jumlahBintang: response.data.data?.jumlah_bintang,
      }));
    } catch (error) {
      throw error;
    }
  };
  const getPengetahuanSaya = async (status: string, batchConvert?: string) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingPengetahuanSaya: true,
    }));

    const params = {
      status_pengetahuan: status,
      ...(status !== "mengikuti" && {
        page: state.pagePengetahuanSaya,
        per_page: state.perPagePengetahuanSaya,
        ...(batchConvert && { batch_convert: batchConvert }),
      }),
    };

    try {
      const response = await axiosInstance.get(state.urlPengetahuanSaya, {
        params,
      });

      setState((prevstate) => ({
        ...prevstate,
        totalPengetahuanSaya: response.data.data?.total ?? 0,
      }));

      setState((prevstate) => ({
        ...prevstate,
        listPengetahuanSaya: response.data.data?.data ?? [],
      }));
    } catch (error) {
      throw error;
    } finally {
      setState((prevstate) => ({
        ...prevstate,
        isLoadingPengetahuanSaya: false,
      }));
    }
  };
  const getListKategoriPengetahuan = async () => {
    try {
      const response = await axiosInstance.get(state.urlKategoriPengetahuan, {
        params: {
          paginate: false,
        },
      });

      const data = response.data.data.map((e) => {
        return {
          value: e.id,
          label: e.nama,
          icon: e.path_icon,
          total: e.total_pengetahuan,
          totalAsync: e.total_pengetahuan_async,
          totalSync: e.total_pengetahuan_sync,
        };
      });

      setState((prevstate) => ({
        ...prevstate,
        listKategoriPengetahuan: data,
      }));
    } catch (error) {
      throw error;
    }
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingSync = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingSync: value,
    }));
  };

  const setPage = (value: number) => {
    setLoading(true);

    getMateri(
      value + 1,
      state.keyword,
      state.jenis_materi_id,
      state.jenis_materi
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setKeyword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const getMateri = async (
    pagee: number,
    keyword: string,
    jenis_materi_id: string,
    jenis_materi: string[]
  ) => {
    setLoading(true);

    try {
      const materiPresenter = container.resolve(MateriPresenter);
      const getAllMateri = await materiPresenter.getAllMateri(
        pagee,
        12,
        keyword,
        jenis_materi_id,
        jenis_materi
      );

      const data = getAllMateri;

      setJumlahPage(data[0].jumlahData / 12);

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchMateri = () => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
    }));
  };

  const getJenisPelatihan = async () => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        ""
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const filterJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, value, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      jenis_materi_id: value,
      page: 1,
    }));
  };

  const filterJenisMateri = (type: string) => {
    let jns_materi;
    if (type == "video") {
      jns_materi = ["video"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["video"],
      }));
    } else if (type == "pdf") {
      jns_materi = ["pdf"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["pdf"],
      }));
    } else if (type == "ppt") {
      jns_materi = ["ppt"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["ppt"],
      }));
    } else if (type == "audio") {
      jns_materi = ["audio"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["audio"],
      }));
    } else {
      jns_materi = [];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: [],
      }));
    }
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
  };

  const filterSemuaJenisMateri = (value: boolean) => {
    let jns_materi: string[];
    if (value) {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: true,
        checkPDF: true,
        checkPPT: true,
        checkAudio: true,
      }));
      jns_materi = ["video", "pdf", "ppt", "audio"];
    } else {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: false,
        checkPDF: false,
        checkPPT: false,
        checkAudio: false,
      }));
      jns_materi = [];
    }
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      jenis_materi: jns_materi,
      checkAll: value,
    }));
  };

  const fetchData = async ({
    status = "",
    jenis_pengetahuan = "",
  }: FetchDataParams) => {
    if (jenis_pengetahuan === "asynchronized" || jenis_pengetahuan === "") {
      setLoading(true);
    }

    if (jenis_pengetahuan === "synchronized") {
      setLoadingSync(true);
    }

    try {
      const response = await axiosInstance.get(state.url, {
        params: {
          status_publish: status ?? "1",
          page:
            jenis_pengetahuan == "synchronized" ? state.pageSync : state.page,
          rows:
            jenis_pengetahuan == "synchronized"
              ? state.perPageSync
              : state.perPage,
          category: state.category,
          keyword: state.keyword,
          kategori_pengetahuan_id: state.kategori_pengetahuan_id,
          sort_by: state.sort_by,
          jenis_pengetahuan: jenis_pengetahuan,
        },
      });

      if (jenis_pengetahuan === "synchronized") {
        setState((prevstate) => ({
          ...prevstate,
          listDataSync: response.data.data,
        }));
        setState((prevstate) => ({
          ...prevstate,
          paginationSync: response.data?.pagination,
        }));
      }

      if (jenis_pengetahuan === "asynchronized" || jenis_pengetahuan === "") {
        setState((prevstate) => ({
          ...prevstate,
          listData: response.data.data,
        }));
        setState((prevstate) => ({
          ...prevstate,
          pagination: response.data?.pagination,
        }));
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setLoadingSync(false);
    }
  };

  const getDetailJP = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${state.urlPengetahuanSaya}/raport/informasi-jp`
      );
      setState((prevstate) => ({
        ...prevstate,
        dataJP: data.data,
      }));
    } catch (error) {
      throw error;
    }
  };

  return (
    <PengetahuanProvider
      value={{
        state,
        getMateri,
        setPage,
        searchMateri,
        setKeyword,
        getJenisPelatihan,
        filterJenisPelatihan,
        filterJenisMateri,
        filterSemuaJenisMateri,
        fetchData,
        setState,
        getListKategoriPengetahuan,
        getPengetahuanSaya,
        getJumlahBintang,
        downloadRaport,
        getDetailJP,
      }}
    >
      {children}
    </PengetahuanProvider>
  );
};

export const usePengetahuanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  usePengetahuanContext,
  Provider,
};
